import React from "react";
import "./styles/PageThree.css";

import Shield from "../assets/shield.png";
import Audience from "../assets/audience.png";
import MobileImage from "../assets/mobile-image-group.png";
import DesktopImage from "../assets/desktop-image-group.png";
import Sparkles from "../assets/sparkles.png";

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';


const PageThree = () => {

  return (
    <section className="page-three landing-page-padding">
      <div className="page-wrapper">
        <div className="flex-row">
          <div className="page-three-column-one">
            <div className="section-heading">
              <h1 className="text-heading text-dark-blue">
                Learn the tools of the trade
              </h1>
              <p className="text-paragraph">
                Confused about where to start? Sites like TradingView have charting tools that help you visualize price action. Start small with{" "}
                <span className="text-faded-blue">indicators</span>, then
                level-up to <span className="text-green">strategies</span>.
              </p>
            </div>
            <img
              className="mobile-image"
              src={MobileImage}
              alt="graph mobile"
            />

            <div className="sub-heading" onClick={() => window.open("https://docs.chart.observer/knowledge-base/automating-crypto-trades/tradingview/technical-anlaysis-indicators/", '_blank', 'noopener,noreferrer')}>
              <img src={Shield} alt="Shield Icon" />
              <div className="sub-heading-content">
                <h2>Alerts on chart indicators <KeyboardArrowRightIcon /></h2>
                <p className="text-paragraph">
                  Easily utilize common indicators like MACD, RSI, and simple
                  Moving Average.
                </p>
              </div>
            </div>
            <div className="sub-heading" onClick={() => window.open("https://docs.chart.observer/knowledge-base/automating-crypto-trades/tradingview/custom-strategies/", '_blank', 'noopener,noreferrer')}>
              <img src={Audience} alt="Audience Icon"/>
              <div className="sub-heading-content">
                <h2>Sophisticated algorithmic strategies <KeyboardArrowRightIcon /></h2>
                <p className="text-paragraph">
                Not afraid of a little coding? Combine several chart indicators on TradingView 
                to achieve a consensus alert “strategy”
                </p>
              </div>
            </div>
          </div>

          <div className="page-three-column-two">
            <img
              className="desktop-image"
              src={DesktopImage}
              alt="graph desktop"
            />
            <img className="sparkles" src={Sparkles} alt="sparkles" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageThree;
