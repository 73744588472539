import React, { useState } from "react";
import { useNavigate } from "react-router";

import "./Signup.css";

import { Navigate } from "react-router";
import { useAuth } from "../../hooks/useAuth";

import Spinner from "../../components/loadingAnimations/Spinner";
import Button from "../../components/primitives/Button/Button";
import Input from "../../components/primitives/Input/Input";
import AuthLayout from "../../components/AuthLayout/AuthLayout";

import { validateEmail } from "../../helpers/emailValidation";
import { validatePassword } from "../../helpers/passwordValidation";
import { validateUsername } from "../../helpers/usernameValidation";


const Signup = () => {

    const { user, signup } = useAuth();

    const navigate = useNavigate();

    const [showSpinner, setShowSpinner] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [username, setUsername] = useState("");

    const [isValidEmail, setIsValidEmail] = useState(true);
    const [isValidUsername, setIsValidUsername] = useState(true);
    const [isValidPassword, setIsValidPassword] = useState(true);
    const [isValidCredentials, setIsValidCredentials] = useState(true);
    const [isCorrectLength, setIsCorrectLength] = useState(true);

    const [isUsedEmail, setIsUsedEmail] = useState(false);
    const [isUsedUsername, setIsUsedUsername] = useState(false);

    const [ackChecked, setAckChecked] = useState(false);
    const [tosChecked, setTosChecked] = useState(false);

    const [invalidAckCheckbox, setInvalidAckCheckbox] = useState(false);
    const [invalidTosCheckbox, setInvalidTosCheckbox] = useState(false);


    const handleAckCheck = () => {
        setInvalidAckCheckbox(false);
        setAckChecked(!ackChecked);
    }
    const handleTosCheck = () => {
        setInvalidTosCheckbox(false);
        setTosChecked(!tosChecked);
    }

    // Temp User Validation Flow
    const signupUser = async () => {
        // Validate email with regex
        setIsValidEmail( validateEmail(email) );

        // Validate Password
        setIsValidPassword( validatePassword(password) );

        setIsValidUsername( validateUsername(username) );

        setIsCorrectLength(username.length > 3 && username.length < 22)

        if (!tosChecked) {
            setInvalidTosCheckbox(true);
        }
        if (!ackChecked) {
            setInvalidAckCheckbox(true);
        }



        if (tosChecked && ackChecked) {
            if (validateEmail(email) && validatePassword(password) && validateUsername(username) && isCorrectLength) {
                setIsValidEmail(true);
                setIsValidPassword(true);
                setIsUsedUsername(false);
                setIsUsedUsername(false);
                setShowSpinner(true);
                await signup(
                    username, 
                    email, 
                    password, 
                    setIsValidCredentials, 
                    setShowSpinner,
                    setIsUsedEmail,
                    setIsUsedUsername
                );   
            }
        }
    };

    if (user) {
        return <Navigate to="/dashboard" />;
    }

    return(
        <AuthLayout>
            <div className="signup-wrapper">
                <div className="signup-form">
                    <div className="logo-container">
                        {/* <Logo size="6xl"/> */}
                        <h1 className="signup-title">Create Account</h1>
                    </div>

                    <div className="signup-current-user">
                        <p className="signup-current-user-text" onClick={() => navigate("/login")}>Already have an account? <span className="signup-span">Login in</span></p>
                    </div>

                    {/* Username */}
                    <div className="username-container">
                        <Input type="text" placeHolder="Username" className="login-page-input" valid={isValidUsername} onChange={setUsername} />
                        {!isValidUsername && 
                            <ul>
                                <li>Usernames can only container letters and numbers with no spaces</li>
                            </ul>
                        }
                        {!isCorrectLength &&
                            <ul>
                                <li>Username must be within 4-20 characters</li>
                            </ul>
                        }
                        {isUsedUsername && 
                            <ul>
                                <li>Username is already in use</li>
                            </ul>
                        }
                    </div>
                    
                    {/* Email */}
                    <div className="username-container">
                        <Input type="text" placeHolder="Email Address" className="login-page-input" valid={isValidEmail} onChange={setEmail} />
                        {!isValidEmail && 
                            <ul>
                                <li>Please enter a valid email address</li>
                            </ul>
                        }
                        {isUsedEmail && 
                            <ul>
                                <li>Email is already in use</li>
                            </ul>
                        }
                    </div>

                    {/* Password */}
                    <div className="password-container">
                        <Input type="password" placeHolder="Password" className="login-page-input" valid={isValidPassword} onChange={setPassword} />
                        {!isValidPassword &&
                            <ul>
                                <li>At least one Uppercase</li>
                                <li>At least one Lowercase</li>
                                <li>At least one Number</li>
                                <li>At least one Special Character: #?!@$%^&*-_</li>
                            </ul>
                        }

                    </div>

                    <div className="checkbox-container">
                        <div className="checkbox-wrapper">

                            <input type="checkbox" checked={ackChecked} onChange={handleAckCheck}/>
                            <p>I understand that this is a “paper trading” account (simulated trading). No real money is deposited or earned. Price data is up to 15 minutes delayed.</p>

                        </div>
                        <div className="checkbox-wrapper">
                            <input type="checkbox" className={invalidTosCheckbox ? "checkbox-invalid" : ""} check={tosChecked} onChange={handleTosCheck}/>
                            <p>I accept the <a href="https://docs.chart.observer/terms-of-use/" target="_blank" rel= "noopener moreferrer">Terms of Service</a></p>
                        </div>
                    </div>

                    <div className="login-button-container">
                        {!showSpinner ? (<Button kind="pill" onClick={signupUser}>
                        Sign up
                        </Button>) : (
                            <Button kind="pill">
                                <Spinner />
                            </Button>
                        )}
                    </div>

                    {/* <div className="login-help">
                        <p onClick={toggleAccountSignup}>Signup</p>
                        <p onClick={toggleForgotPassword}>Forgot Password?</p>
                    </div> */}
                </div>
            </div>
        </AuthLayout>
    );
};

export default Signup;