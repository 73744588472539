import React, { useEffect, useState } from "react";

import "./ManualTrade.css";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";

import { getCoinBaseData, getTransactionsByUserId, manualTrade, getUserBalance } from "../../helpers/api";

import { formatDataForGrid } from "../../helpers/formatOpenPostions";

import DropDown from "../../components/dropDown/DropDown";

import { useAuth } from "../../hooks/useAuth";
import { useTheme } from "../../hooks/useTheme";

import Button from "../../components/primitives/Button/Button";

import TradeOrderInput from "./TradeOrderInput";
import TradingViewWidget from "../../components/TradingViewWidget/TradingViewWidget";
import TradeAction from "./TradeAction";
import Spinner from "../../components/loadingAnimations/Spinner";


const ManualTrade = () => {

  const { user } = useAuth();
  const { isMobile } = useTheme();

  const [isInputValid, setIsInputValid] = useState(true);

  const [userHoldings, setUserHoldings] = useState(null);
  const [coinbaseData, setCoinbaseData] = useState(null);

  const [tokenChoice, setTokenChoice] = useState("BTC");
  const [tokenHoldingAmount, setTokenHoldingAmount] = useState(0);
  const [tokenPrice, setTokenPrice] = useState(0);

  const [tradeOption, setTradeOption] = useState("buy");
  const [isUSD, setIsUSD] = useState(true);

  const [tradeAmountUSD, setTradeAmountUSD] = useState(0);
  const [tradeAmountCrypto, setTradeAmountCrypto] = useState(0);

  const [userBalance, setUserBalance] = useState(0);

  const [showSpinner, setShowSpinner] = useState(false);

  // Fetch the most up to date user holdings and coinbase price data
  const fetchUserHoldings = async () => {
    return Promise.all([
      getTransactionsByUserId(user.userId),
      getCoinBaseData(),
      getUserBalance(user.userId),
    ]).then(([transactionData, coinbaseData, userBalance]) => {
      setUserBalance(parseFloat(userBalance));

      setCoinbaseData(coinbaseData);
      if (!tokenPrice) {
        setTokenPrice( 1 / coinbaseData.data.rates["BTC"])
      }
      const userHoldings = {};
      const formatedTransactionData = formatDataForGrid(
        transactionData,
        coinbaseData
      );
      // console.log("Formated Transaction Data", formatedTransactionData);
      formatedTransactionData.forEach((token) => {
        // userHoldings.push(
        //     {
        //         "token"     : token.id.split("USD")[0],
        //         "amount"    : token.amount
        //     }
        //  );
        const tokenName = token.id.split("USD")[0].trim();
        userHoldings[tokenName] = { amount: token.amount };
      });

      // Since current token on page load is BTC, look to see if user has BTC holdings and update the current holdinfs value.
      if (userHoldings.hasOwnProperty(tokenChoice)) {
        setTokenHoldingAmount(userHoldings[tokenChoice]["amount"]);
      }
      setUserHoldings(userHoldings);
    });
  };

  const handleTokenSelect = (token) => {
    // console.log(token)
    setTokenChoice(token);
    setTokenPrice( 1 / coinbaseData.data.rates[token]);
    setTradeAmountUSD(0);
    setTradeAmountCrypto(0);
    // console.log("Token: ", tokenChoice);
    // console.log("Token Price: ", tokenPrice);

    // check if user has holdings of the token
    if (userHoldings.hasOwnProperty(token)) {
      setTokenHoldingAmount(userHoldings[token]["amount"]);
    } else {
      setTokenHoldingAmount(0);
    }
  };

  const handleAmountChange = (amount) => {
    if (isUSD) {
      setTradeAmountUSD(amount);
      setTradeAmountCrypto(amount/tokenPrice);
    } else {
      setTradeAmountUSD(amount * tokenPrice);
      setTradeAmountCrypto(amount);
    }
  }

  // Using Crypto Amount in trade api call
  const handleOrderSubmit = async () => {
    if (isInputValid && tradeAmountCrypto > 0) {
      setShowSpinner(true);

      const tradeData = {
        tokenpair: tokenChoice + "USD",
        count: tradeAmountCrypto,
        usdprice: tokenPrice,
        action: tradeOption,
        exchange: "Coinbase",
        user: user.userId,
        pin: user.webhookId,
      };

      // console.log(tradeData)

      return Promise.all([
        manualTrade(tradeData)
      ]).then(([tradeResponse]) => {
        setShowSpinner(false);
        if (tradeResponse) {
          // console.log(tradeResponse);
          if (tradeOption === "buy") {
            setUserBalance(parseFloat(userBalance - tradeAmountUSD));
            user.walletBalance = parseFloat(userBalance - tradeAmountUSD);
            setTokenHoldingAmount(tokenHoldingAmount + tradeAmountCrypto);
          } else if (tradeOption === "sell") {
            // Currently Confiugred to sell all of current holdings

            // Any amount
            // setUserBalance(parseFloat(userBalance + tradeAmountUSD));
            // Selling whole holding amount
            setUserBalance(parseFloat(userBalance + (tokenHoldingAmount*tokenPrice)));

            // Any amount
            // user.walletBalance = parseFloat(userBalance + tradeAmountUSD);
            // Selling whole holding amount
            user.walletBalance = parseFloat(userBalance + (tokenHoldingAmount*tokenPrice));

            setTokenHoldingAmount(tokenHoldingAmount - tradeAmountCrypto);

          }
          // Resetting the amount field after buy/sell
          setTradeAmountCrypto(0);
          setTradeAmountUSD(0);
          fetchUserHoldings();
        }
      });
    }
  }


  useEffect(() => {
      fetchUserHoldings().then();

      // Call coin gecko API
      // if (!coinData) {
      //   getCoinData("bitcoin")
      //     .then(coinData => {
      //       setCoinData(coinData)
      //     })
      //     .catch(error => {
      //       console.error("Could not get coin data: ", error)
      //     })
      // }


  }, []);

  // Currenty way of handling this + trade input is hot garbage... refactor.
  // May still want to use this is reset input to 0 when user changes option
  // useEffect(() => {
  //   if (tradeOption === "sell") {
  //     setTradeAmountCrypto(tokenHoldingAmount);
  //     setTradeAmountUSD(tokenHoldingAmount * tokenPrice);
  //   }
  // }, [tradeOption]);

  return (
    <DashboardLayout page={"Manual Trade"}>
      <div className="manual-trade">
        <div className="manual-trade-content">
          <div className="manual-trade-container">
            <div className="manual-trade-dashboard">
              <div className="chart-container">
                <div className="chart-coin-select-container">
                  {coinbaseData && 
                    <DropDown
                      coinbaseData={coinbaseData}
                      tokenOptions={null}
                      setTokenChoice={setTokenChoice}
                      handleTokenSelect={handleTokenSelect}
                    />
                  }
                </div>
                <div className="chart-data-display">
                  {tokenChoice && <TradingViewWidget tokenChoice={tokenChoice}/>}
                </div>
              </div>

              {/* Manual Trade Options */}
              <div className="manual-trade-action-container">

                  {/* Buy and Sell Buttons */}
                  <TradeAction tradeOption={tradeOption} setTradeOption={setTradeOption}/>

                  {/* Available To Trade */}
                  <div className="available-wrapper">
                    {isMobile ? (
                      <div className="available-container-mobile">
                        <h3>Available to trade</h3>
                        {tradeOption === "buy" ? (
                          <p>{userBalance.toLocaleString("en-US", {style: "currency", currency: "USD",})}</p>
                        ) : (
                          <p>{tokenHoldingAmount.toFixed(8)} {tokenChoice}</p>
                        )}

                      </div>
                    ) : (
                      <div className="available-container">
                        <h3>Available to trade</h3>
                        <div className="available-token-wrapper">
                          <h4>{tokenChoice}</h4>
                          <p>{tokenHoldingAmount}</p>
                        </div>
                        <div className="available-usd-wrapper">
                          <h4>USD</h4>
                          <p>{userBalance.toLocaleString("en-US", {style: "currency", currency: "USD",})}</p>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Trade Information */}
                  <div className="trade-information-wrapper">
                    { tokenChoice && 
                      <TradeOrderInput 
                        // isReadOnly={tradeOption === "sell"}
                        tradeOption={tradeOption}
                        userHoldings={userHoldings}
                        isReadOnly={false}
                        tokenChoice={tokenChoice}
                        tokenPrice={tokenPrice} 
                        userBalance={userBalance} 
                        // isUSD={tradeOption === "sell" ? false : isUSD}
                        isUSD={isUSD}
                        setIsUSD={setIsUSD}
                        // amount={tradeOption === "sell" ? tokenHoldingAmount : (isUSD ? tradeAmountUSD : tradeAmountCrypto)}
                        amount={isUSD ? tradeAmountUSD : tradeAmountCrypto}
                        handleAmountChange={handleAmountChange}
                        isInputValid={isInputValid}
                        setIsInputValid={setIsInputValid}
                      />
                    }
                  </div>

                  <div className="trade-button-wrapper">
                    <div className="trade-button-container">
                      {isMobile ? (
                        <>
                          <div className="trade-total-amount">
                            <h4>Total</h4>
                            <p>{parseFloat(tradeAmountUSD).toLocaleString("en-US", {style: "currency", currency: "USD",})}</p>
                          </div>
                        </>

                      ) : (
                        <>
                          <div className="trade-total-wrapper">
                            <h4>{tokenChoice}*</h4>
                            <p>{tokenPrice > 1 ? `${tokenPrice.toLocaleString("en-US", {style: "currency", currency: "USD",})}` : `$${tokenPrice.toFixed(4)}`}</p>
                          </div>
                          <div className="trade-total-wrapper">
                            <h4>{tokenChoice} Amount</h4>
                            <p>{tradeAmountCrypto}</p>
                          </div>
                          <div className="trade-total-amount">
                            <h4>Total</h4>
                            <p>{parseFloat(tradeAmountUSD).toLocaleString("en-US", {style: "currency", currency: "USD",})}</p>
                          </div>
                        </>
                      )}
                      {!showSpinner ? (
                        <Button kind={`trade-${tradeOption}`} onClick={handleOrderSubmit}>{tradeOption.toUpperCase()}</Button>
                      ) : (
                        <Button kind={`trade-${tradeOption}`}>
                          <Spinner />
                        </Button>
                      )}
                    </div>
                  </div>
                  {!isMobile && 
                    <div className="manual-trade-action-footer">
                      <p>* Token Price data may be delayed up to 15 Minutes</p>
                    </div>
                  }
              </div>

            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default ManualTrade;
