import React, { useState } from "react";

import "./WebhookUrlInstructions.css";

import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import WebhookSettings from "../../images/webhook_settings_light.png";
import WebhookNotifications from "../../images/webhook_notifications_light.png";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { useAuth } from "../../hooks/useAuth";

const WebhookUrlInstructions = () => {
  const { user } = useAuth();

  const [showCopyMessage, setShowCopyMessage] = useState(false);

  const copyText = () => {
    setShowCopyMessage(false);

    const webhookURL = `https://g2uyqqluc4.execute-api.us-east-2.amazonaws.com/dev/transaction/${(user && user.webhookId) ? user.webhookId : "xxxxxxx"}`
    navigator.clipboard.writeText(webhookURL)
      .then(() => {
        setShowCopyMessage(true);

        const _ = setTimeout(() => {
          setShowCopyMessage(false);
        }, 2000);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      })
  }

  return (
    <DashboardLayout page={"Webhook Instructions"}>
      <div className="webhook-url-instructions">
        <div className="webhook-url-instructions-content">
          <h2 className="webhook-info-title text-header">
            Sending us a BUY or SELL alert from Tradingview
          </h2>
          <h2 className="webhook-bullet text-header">
            <p className="text-base">
              <b>Step 1:</b> 
                <ul>
                  <li>Create an alert that triggers when your desired trade execution condition is met.</li>
                  <li>Alerts can be placed on horizontal lines (easiest), or on strategies (complex algorithms)</li>
                  <li>If you are brand new to Tradingview, you can find their instructions on <a target='_blank' href='https://www.tradingview.com/support/solutions/43000520149-about-tradingview-alerts/?aff_id=134695'>setting alerts</a>,
              and you will also want to read up on <a target='_blank' href='https://www.tradingview.com/chart/b1S1ask9/?solution=43000529348?aff_id=134695'>webhooks</a>.             
                  </li>
                  <li><b>Remember</b> to only set alerts on <b>USD</b> trading pairs available on Coinbase.  <a target='_blank' href='https://www.tradingview.com/watchlists/138162224/?aff_id=134695'>Check the list</a> here first!</li>
                </ul>
            </p>
            <p>&nbsp;</p>
          </h2>
          <h2 className="webhook-bullet text-header">
            <p className="text-base">
              <b>Step 2:</b> 
                <ul>
                  <li>When creating an alert, you will see a <b>Settings</b> tab, where you will find the <b>Message</b> box. </li>
                  <li>This is the message that gets automatically sent to your unique webhook URL (shown in Step 3 below).</li>
                  <li>Here you will enter the message body, based on the example we have provided. </li>
                  <li>If the Message box turns red, you have an error in your formatting. </li>
                  <li>Be sure to copy the code exactly, but change the values for the count and the action ("buy" or "sell").</li>
                  <li>If you run into problems, you can test your message in a JSON validator like <a target='_blank' href='https://jsonlint.com/'>jsonlint.com</a>.</li>
              </ul>
            </p>
          </h2>
          <div className="webhook-alert-container">
            <img src={WebhookSettings} alt="webhook-alert" className="webhook-url-instructions-image" />
            <div className="code-snippet">
              <p className="text-base">
                &#123;
                <br></br> "tokenpair":"&#123;&#123;ticker&#125;&#125;",
                <br></br> "count":"10",
                <br></br> "usdprice":"&#123;&#123;close&#125;&#125;",
                <br></br> "action":"buy",
                <br></br> "exchange":"&#123;&#123;exchange&#125;&#125;",
                <br></br> "user":"<span className="webhook-url-instructions-span">{(user && user.userId) ? user.userId : "xxxxxxx"}</span>"
                <br></br>&#125;
              </p>
            </div>
          </div>
          <h2 className="webhook-bullet text-header">
            <p className="text-base">
              <ul>
                <li>The above example is formatted to send our platform an alert to buy 10 BTC at
                the USD price when the current candle closes. Alternatively, you can replace
                “buy” with “sell” to completely sell all of your open positions for
                that tokenpair. A webhook "sell" always sells the entire amount you have.
                <p>&nbsp;</p>
                </li>
                <li> The easiest way to get started is to create your alert on a horizontal line or a specific price, and 
                set the alert to trigger "Only Once" on the close of the candle. This will ensure that the alert is triggered one single time, 
                and becomes paused until you re-enable it. 
                <p>&nbsp;</p>
                </li> 
                <li>
                If you are adding an alert to a more complex Tradingview <a target='_blank' href='https://www.tradingview.com/support/solutions/43000481368-strategy-alerts/?aff_id=134695'>strategy</a>, you can use
                variables like &#123;&#123;strategy.order.action&#125;&#125; to dynamically set
                the "buy" or "sell" value, based on what the Tradingview strategy is alerting for. Most strategies will be capable of producing both buy and sell alerts, but you will want to make sure the strategy is set to go "long", and not "short". 
                <p>&nbsp;</p>
                <p>Please remember that all orders sent to our platform are only simulated orders, sometimes referred to as “paper-trading”.</p>
                </li> 
              </ul>
            </p>
          </h2>
          <hr className="webhook-url-instructions-hr" />
          <hr/>
          <h2 className="webhook-bullet text-header">
            <p className="text-base">
              <b>Step 3:</b> 
                <ul>
                  <li>In the <b>Notifications</b> tab for the Tradingview alert, check the box for <b>Webhook URL</b>, and enter the URL we provided here.</li>
                  <li>It needs to be all one line, so you may want to use the copy button to send it to your clipboard.</li>
                  <li>This is your unique webhook URL for your account. <b>DO NOT</b> share this URL with anyone!</li>
                  <li>Feel free to also check any of the other boxes to be alerted in various ways</li>
                </ul>  
            </p>
          </h2>
          <div className="webhook-alert-container">
            <img src={WebhookNotifications} alt="webhook-alert" className="webhook-url-instructions-image" />
            <div className="code-snippet">
              <h2 className="code-snippet-header text-base">Your Webhook URL for ChartObserver:</h2>
              <div className="webhook-url-copy-container">
                <ContentCopyIcon className="copy-icon" onClick={copyText} />
                <p className="text-base">
                  <span className="webhook-url-instructions-span">https://g2uyqqluc4.execute-api.us-east-2.amazonaws.com/dev/transaction/{(user && user.webhookId) ? user.webhookId : "xxxxxxx"}</span>
                </p>
              </div>
              {showCopyMessage && <p className="text-small">Copied to clipboard!</p>}
            </div>
          </div>
          
        </div>
      </div>
    </DashboardLayout>
  );
};

export default WebhookUrlInstructions;
