import React from "react";
import "./styles/PageTwo.css";

import Box from "../assets/box.png";
import Calendar from "../assets/calendar.png";
import Statistics from "../assets/statistics.png";

const PageTwo = () => {
  return (
    <section className="page-two landing-page-padding">
      <div className="page-wrapper">
        <div className="page-two-header">
          <h1 className="page-two-title text-heading text-dark-blue">
            A sandbox for humans and bots alike
          </h1>
          <h2 className="page-two-sub-title">
            Easy, push-button trading. Or send us your alerts!{" "}
          </h2>
        </div>
        <div className="page-two-card-container">
          <div className="page-two-card">
            <div className="card-header">
              <img src={Box} alt="" />
            </div>
            <h2>Easily start basic trading today</h2>
            <p>
              No experience? No problem. You get $10k in fake USD to immediately begin simulated buying and selling.
            </p>
          </div>
          <div className="page-two-card">
            <div className="card-header">
              <img src={Calendar} alt="" />
            </div>
            <h2>Explore automated trading</h2>
            <p>
              Automated BUY & SELL alerts are easy to setup if you have a
              Tradingview account. We are built to receive webhooks.
            </p>
          </div>
          <div className="page-two-card">
            <div className="card-header">
              <img src={Statistics} alt="" />
            </div>
            <h2>Adjust your strategy over time</h2>
            <p>
              Are you timing your trades well? Track your simulated transactions with "paper trading". Gain experience the safe way.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageTwo;
