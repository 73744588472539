import axios from "axios";
import axiosRetry from "axios-retry";

// Configuring axios-retry 
axiosRetry(axios, {
    retries: 3,
    // retryDelay: (retryCount) => {
    //     return retryCount * 1000;
    // },
    // using exponential delay until we sort out a fix on the back end
    retryDelay: axiosRetry.exponentialDelay,
    retryCondition: (error) => {
        // Retry on 5xx errors or network errors
        // console.log("retrying api call");
        return axiosRetry.isNetworkOrIdempotentRequestError(error);
    },
});

export const getTransactionsByUserId = async (userId) => {
    var transactionData = null;
    try {
        const transactionsURL = `https://g2uyqqluc4.execute-api.us-east-2.amazonaws.com/dev/transactions/${userId}`;
        const headers = {
          Accept: "application/json",
          "Authorization": "Bearer {{bearerToken}}",
          "x-api-key": "PMAK-64daaabf2ddbc0004382ceaa-bc788868aaaa3b24ee99d865bb91f2ac2e"
        };        
        transactionData = await axios.get(transactionsURL, { headers});
        return transactionData.data;
    } catch (error) {
        // console.error("Could not Pull Transaction Data from Endpoint: ", error);
    }

    return transactionData;

}

export const getCoinBaseData = async () => {
    var coinbaseData = null;

    try {
        const url = "https://api.coinbase.com/v2/exchange-rates?currency=USD";
        const coinbaseResponse = await axios.get(url);
        coinbaseData = coinbaseResponse.data;

    } catch (error) {
        // console.error("Could not pull Token Price data from Coinbase: ", error);
    }

    return coinbaseData;
}

export const userValidation = async (email, password) => {
    const url = 'https://g2uyqqluc4.execute-api.us-east-2.amazonaws.com/dev/users';
    const requestData = {
        email: email,
        password: password
    }
    const headers = {
        'Content-Type': 'application/json',
    };
    try {
        const loginResponse = await axios.post(url, requestData, { headers});
        return loginResponse;
    } catch (error) {
        // console.error("Could not reach Login Server: ", error);
        return null;
    }
}

export const getUserBalance = async (uid) => {
    const url = `https://g2uyqqluc4.execute-api.us-east-2.amazonaws.com/dev/users/balance/${uid}`

    try {
        const response = await axios.get(url);
        const userBalance = response.data[0].usdBalance.N;
        return userBalance;
    } catch (error) {
        // console.error("Error:", error);
    }
    
};

export const manualTrade = async (tradeData) => {
    const apiUrl = "https://g2uyqqluc4.execute-api.us-east-2.amazonaws.com/dev/transaction/manual";
    
    const headers = {
        'Content-Type': 'application/json',
    };
    
    try {
        const tradeResponse = await axios.post(apiUrl, tradeData, { headers});
        return tradeResponse;
    } catch (error) {
        console.error("Error:", error);
        return null;
    }
};

export const getCoinData = async (token) => {
    const url = `https://api.coingecko.com/api/v3/coins/${token}/market_chart`;

    const params = {
        vs_currency: 'usd',
        days: 1
    }

    const headers = {
        'x-cg-demo-api-key': 'CG-cf3KvCHqv6gQQFQis2A5gKPL'
    }

    try {
        const response = await axios.get(url, {params: params, headers: headers});

        // console.log(response)

        // Take the last 48 prices (4 hours at 5 min)
        return(response.data.prices.slice(-48))
        
    } catch (error) {
        // console.error('Error:', error);
        throw error;
    }
          
    
}