import { Typography } from "@mui/material";

import "./Column.css";

const fontSize = 14;

export const columns = [
    { 
        field: "id", 
        headerName: "Token", 
        flex: 1,
        cellClassName: "--cell --token-cell --cell-id"
    },
    { 
        field: "amount", 
        headerName: "Amount",
        flex: 1,
        cellClassName: "--cell",
        renderCell: ({row: {amount}}) => {
            return(
                <Typography fontSize={fontSize} >
                    {amount.toFixed(2)}
                </Typography>
            )
        }
    },
    { 
        field: "totalCost", 
        headerName: "Total Cost",
        flex: 1,
        cellClassName: "--cell",
        renderCell: ({ row: {totalCost}}) => {
            return(
                <Typography fontSize={fontSize}>
                    {totalCost.toLocaleString("en-US", {style: "currency", currency: "USD"})}
                </Typography>
            );
        }
    },
    { 
        field: "avgPrice",
        headerName: "Avg. Price",
        flex: 1,
        cellClassName: "--cell",
        renderCell: ({ row: {avgPrice}}) => {
            return(
                <Typography fontSize={fontSize}>
                    {/* {avgPrice.toLocaleString("en-US", {style: "currency", currency: "USD"})} */}
                    {avgPrice < 1 ? `$${(avgPrice).toFixed(6)}` : avgPrice.toLocaleString("en-US", {style: "currency", currency: "USD"})}
                </Typography>
            );
        }
    },
    { 
        field: "currentPrice",
        headerName: "Current Price",
        flex: 1,
        cellClassName: "--cell",
        renderCell: ({ row: {currentPrice}}) => {
            // Format price to have 2 or more decimal places (if it has a decimal)
            // const priceString = currentPrice.toString();
            // var formatedPrice = currentPrice;
            // if (priceString.includes(".")) {
            //     if (priceString.split(".")[1].length < 2) {
            //         formatedPrice = currentPrice.toFixed(2);
            //     }
            // }
            return(
                <Typography fontSize={fontSize}>
                    {currentPrice < 1 ? `$${currentPrice.toFixed(6)}` : currentPrice.toLocaleString("en-US", {style: "currency", currency: "USD"})}
                </Typography>
            );
        }
    },
    { 
        field: "profit", 
        headerName: "Profit",
        flex: 1,
        renderCell: ({ row: { profit }}) => {
            const profitColor = profit >= 0
                ? "green"
                : "red"
            return(
                <Typography color={profitColor} fontSize={fontSize}>
                    {profit.toFixed(2)}%
                </Typography>
            );
        }
    },
]
