import React, { useState, useContext } from "react";
import "./DashboardLayout.css";
import Button from "../primitives/Button/Button";
import Logo from "../primitives/Logo/Logo";
import DashboardLinks from "../DashboardLinks/DashboardLinks";
import TopBar from "../topBar/TopBar";
import DashboardFooter from "../DashboardFooter/DashboardFooter";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import User from "../User/User";

import ModalController from "../ModalController/ModalController";
import DashboardContext from "../../context/DashboardContext";

import { useTheme } from "../../hooks/useTheme";


const DashboardLayout = ({page, children, ...props }) => {
  const {
    isLoginVisible,
    setIsLoginVisible,
  } = useContext(DashboardContext);


  const { isDarkMode, isSideBarExpanded, toggleSideBar } = useTheme();

  const [isMobileContainerOpen, setIsMobileContainerOpen] = useState(false);

  return (
    <div className="dashboard-layout">
      {/* Top bar for Mobile View - hidden on desktop view*/}
      <div className="topbar-container--mobile">
        <TopBar page={page} />
      </div>
      <div className="dashboard-hamburger-button-container">
        <Button
          kind="tertiary"
          onClick={() => setIsMobileContainerOpen(true)}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3 18H21V16H3V18ZM3 13H21V11H3V13ZM3 6V8H21V6H3Z"
              fill={isDarkMode ? "#e0e0e0" : "black"}
              fillOpacity="0.56"
            />
          </svg>
        </Button>
      </div>
      <aside
        className={`sidebar ${isSideBarExpanded ? "" : "sidebar--minimized"}`}
      >
        <div className="sidebar-content">
          <div className={isSideBarExpanded ? "sidebar-header--expanded" : "sidebar-header--minimized"}>
            <Logo isSideBarExpanded={isSideBarExpanded} />
            <Button
              kind="tertiary"
              onClick={() => toggleSideBar()}
            >
              <ExpandLessIcon className="material-icon" />
            </Button>
          </div>

          <DashboardLinks
            isSideBarExpanded={isSideBarExpanded}
          />
          <User className="sidebar-user" />
        </div>
      </aside>

      {/* Mobile Navigation */}
      <div
        className={`mobile-side-container ${
          isMobileContainerOpen ? "mobile-side-container--open" : ""
        }`}
      >
        <div className="close-button-container">
          <div className="title">
            <Logo isSideBarExpanded={isSideBarExpanded}/>
          </div>

          <Button
            kind="tertiary"
            onClick={() => setIsMobileContainerOpen(false)}
          >
            X
          </Button>
        </div>
        <nav>
          <ul className="mobile-header-nav">
            <DashboardLinks
              isSideBarExpanded={true}
            />
          </ul>
        </nav>
      </div>
      {/* End Mobile Navigation */}
      
      <div className="dashboard-layout-content">
        <div className="topbar-container--desktop">
          <TopBar page={page} />
        </div>
        <header className="dashboard-header">
          <div className="dashboard-navigation">
            <div className="title">
              {/* Removing for mobile view */}
              {/* <Logo isSideBarExpanded={isSideBarExpanded}/> */}
            </div>
          </div>
        </header>
        <main className="main-content">{children}</main>
        <DashboardFooter />
      </div>
      {/* {isLoginVisible && <Login user={user} setUser={setUser} />} */}
      {isLoginVisible && <ModalController />}
    </div>
  );
};

export default DashboardLayout;
